import { Component } from "react";

export default class BlogComponent extends Component {

    componentDidMount(){
        window.location.replace('https://injection.sh/injection-blog')
    }

    render(){
        return null;
    }

}